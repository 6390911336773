import { error } from "console";
import { deleteData, getData, postData } from "./common.service"

export async function getPlots(text: string = '', plotIds: string[] = [], skip = 0) {
    const qs: any = {
        where: {
            or:
                [
                    { plotId: { like: `.*${text}.*`, options: 'i' } },
                    { farmId: { like: `.*${text}.*`, options: 'i' } },
                    { name: { like: `.*${text}.*`, options: 'i' } },
                    { place: { like: `.*${text}.*`, options: 'i' } },
                    { taluka: { like: `.*${text}.*`, options: 'i' } },
                    { district: { like: `.*${text}.*`, options: 'i' } },
                    { farmUserId: { like: `.*${text}.*`, options: 'i' } }
                ]
        },
        order: 'created_date DESC',
        limit: 20,
        skip: skip
    };
    if (plotIds.length) {
        qs.where = {
            and: [
                {
                    plotId: { inq: plotIds }
                },
                qs.where
            ]
        }
    }
    qs.include = ['device']; // Include the 'device' attribute in the query
    return getData('/plots?filter=' + JSON.stringify(qs), {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    }).then(data => data.json())
}

export async function getPlot(plotId: string) {
    const qs = {
        include: ['device', 'cropsSown']
    };
    return getData(`/plots/${plotId}` + '?filter=' + JSON.stringify(qs), {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function searchPlot(plotId: string){
    const qs = {
        'fields': {
            'plotId': true
        }
    }
    return getData(`/plots/${plotId}?filter=` + JSON.stringify(qs), { sendTokenInUrl: true })
            .then(data => data.json())
            .catch((e) => console.log(e));
    ;
}

export async function getPlotTypes() {
    return getData('/plots/plottypes', {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getAvailableDevices() {
    return getData('/devices/availabledevices', {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getAvailableDevicesPaginated(text: string = "", skip: number = 0, limit: number = 20){
    const qs = {
        where: {
            imei: { 
                like: `.*${text}.*`,  
            },
            isAssigned: false
        },
        limit: limit,
        skip: skip,
        fields: {
            imei: true,
            deviceId: true
        },
        order: ['imei ASC']
    };

    return getData("/devices?filter=" + JSON.stringify(qs), {
        sendTokenInUrl: true
    }).then(data => {
       return data.json().then((jsonData: any[]) => {
        const modifiedData = jsonData.map(item => {
            const name = item.imei;
            return {...item, name};
        });
        return modifiedData;
       });
    })
}

export async function getAssignedDevice(imei: string) {
    const qs = {
        where: {
            isAssigned: true,
            imei: imei
        },
        limit: 1
    };
    return getData('/devices?filter=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function unassignIMEI(imei: string) {
    const qs = {
            isAssigned: true,
            imei: imei
    };
    return postData('/devices?where=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        usePatchMethod: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: {
            isAssigned: false,
        }
    }).then(data => {data.json()
    }).catch(err=> console.log(err))
}
export async function assignIMEI(imei: string) {
    const qs = {
            isAssigned: false,
            imei: imei
    };
    return postData('/devices?where=' + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        usePatchMethod: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: {
            isAssigned: true,
        }
    }).then(data => {data.json()
    }).catch(err=> console.log(err))
}

export async function getSupportedCrops() {
    return getData('/crops/supported', {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getSupportedRootStocks(cropId: string) {
    return getData(`/crops/${cropId}/rootstocks`, {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getCropVarieties(cropId: string) {
    return getData(`/crops/${cropId}/crop-varieties`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getSoilChars() {
    return getData('/SoilChars', {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getDeviceTypes() {
    return getData('/devices/devicetypes', {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getSensors(deviceType: string) {
    return getData(`/devices/getSensors/${deviceType}`, {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getNearestPlots(location: any) {
    return postData('/plots/getNearestPlots', {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: {
            latitude: location?.latitude,
            longitude: location?.longitude,
            limit: 50
        }
    }).then(data => data.json())
}

export async function getPlaces() {
    return getData('/farms/places', {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getFarmsAtPlace(place: string) {
    return getData(`/farms/places/${place}`, {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getMasterPlots(farmId: string) {
    return getData(`/plots/${farmId}/masterplots`, {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function registerPlot(plotData: any) {
    return postData('/plots/register', {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: plotData
    })
}

export async function updatePlot(plotData: any) {
    return postData('/plots/updatePlot', {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: plotData
    })
}

export async function getInactivePlots(sinceHours: number,userProifle:any, geolocation?: any, plotIds?: string[]) {
    const userRegions:string[] = userProifle?.regionIds || []
    let url = `/inactive-devices?sinceHours=${sinceHours}`;
    if(userRegions?.length){
        url += `&regionIds=${userRegions?.join(",")}`
    }
    if (geolocation && geolocation?.coords?.latitude && geolocation?.coords?.longitude) {
        url += `&latitude=${geolocation?.coords?.latitude}&longitude=${geolocation?.coords?.longitude}`;
    }
    if(plotIds&& plotIds.length > 0) {
        const queryString = {
            where: {
                plotId: { in: plotIds },
            },
        };
        url += (url.includes('?') ? `&` : `?`) + `filter=${JSON.stringify(queryString)}`;
    }
    return getData(url, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json()).catch(e => [])
}

export async function getExpiredPlots(geolocation: any, plotIds: string[] = []) {
    let url = `/expired-plots`;
    if (geolocation?.coords?.latitude && geolocation?.coords?.longitude) {
        url += `?latitude=${geolocation?.coords?.latitude}&longitude=${geolocation?.coords?.longitude}`;
    }
    if(plotIds.length > 0) {
        const queryString = {
            where: {
                plotId: { in: plotIds },
            },
        };
        url += (url.includes('?') ? `&` : `?`) + `filter=${JSON.stringify(queryString)}`;
    }
    return getData(url, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function getOutlierPlots(sinceHours: number, geolocation: any, plotIds: string[] = []) {
    let url = `/get-anomalous-plots?sinceHours=${sinceHours}`;
    if (geolocation?.coords?.latitude && geolocation?.coords?.longitude) {
        url += `&latitude=${geolocation?.coords?.latitude}&longitude=${geolocation?.coords?.longitude}`;
    }
    if(plotIds.length > 0) {
        const queryString = {
            where: {
                plotId: { in: plotIds },
            },
        };
        url += (url.includes('?') ? `&` : `?`) + `filter=${JSON.stringify(queryString)}`;
    }
    return getData(url, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json()).catch(e => [])
}

export async function getPlotSensorData(plotId: string, sinceHours: number) {
    let url = `/sensor-data/${plotId}?sinceHours=${sinceHours}`;
    return getData(url, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export function filterPlots(plots: any[], searchText: string = '') {
    return plots
        .filter((item: any) => item?.plotId?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.place?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.farm?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.farm?.farmUser?.name.toLowerCase().includes(searchText.toLowerCase())
        );
}

export async function getPlotIdsByCrops(cropIds: string[]) {
    const qs = {
        where: {
            isCropEnded: false,
            cropId: {
                inq: cropIds
            }
        },
        fields: ["plotId"]
    };
    return getData('/cropssown?filter=' + JSON.stringify(qs), {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getPlotIdsByTags(tags: string[]) {
    const qs: any = {
        where: {
             tags: { inq: tags } ,
        },
        fields: ["plotId"]
    };
    return getData('/plots?filter=' + JSON.stringify(qs), {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getPlotTags() {
    return getData(`/plots/tags`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export async function postpubliclyAccessible(plotId: string, publiclyAccessible: boolean) {
    return postData(`/plots/${plotId}`, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        usePatchMethod: true,
        body: {
        publiclyAccessible: publiclyAccessible
        }
    })
}

export async function updatePlotsLocation(plotId: string, payload: any) {
  const url = `/plots/${plotId}`;

  return postData(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    body: payload,
    usePatchMethod: true,
  });
}

export async function deletePlotById(plotId: string){
    const url = `/plots/${plotId}`;
    return deleteData(url, {
        headers: {
            'Accept': 'application/json'
        },
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    });
}

export async function getAiFilteredPlot(query: string) {
    return getData('/plots/similar?query=' + query, {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    }).then(data => data.json())
}
